export const sygnet = ['160 160', `
  <title>Shareblad logo</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 195.9 195.3" style="enable-background:new 0 0 195.9 195.3;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#FE7D52;}
</style>
  <g>
	<path class="st0" d="M-29.5,243.8c3.5-0.7,4.6-3.3,6.7-4.4c2.9-1.6,5.8-3,8.9-0.9c3.2,2.1,3.7,5.3,2.6,8.8c-2,6.7-4.1,13.4-6,20.1
		c-0.4,1.4-1.1,3.3,0.3,4.2c1.6,1,2.6-0.9,3.6-1.9c3-2.8,5.1-6,5.9-10.2c1.1-5.6,3-10.9,6-15.8c4.2-6.8,9.3-8.3,16.4-4.6
		c1.3,0.6,1.9,0.7,3-0.1c3.4-2.4,6.4-0.5,9.1,1.5c2.9,2.2,0.4,4.5-0.4,6.7c-2.5,6.7-5.5,13.2-6.9,20.3c-0.3,1.3-1,3.2,0.8,3.9
		c1.4,0.6,2.3-1.1,3.1-2.1c5.4-7,9.5-14.9,13.4-22.8c1-2.1-0.1-3.4-1-5c-2.1-3.8-1.8-7.4,1.1-10.6c1.8-2.1,4.3-2.8,6.8-1.7
		c2.6,1.1,3.5,3.4,3.2,6.2c-0.5,5.6-0.5,5.7,5.2,4.3c3-0.7,5.3,0.4,7.2,2.4c2,2.2,1.1,4.6-0.3,6.9c-3.5,5.7-6.9,11.4-8,18.2
		c-0.2,1.4-0.8,3.4,0.5,4.2c1.8,1.1,3-0.9,4-2c4.1-4.2,7.7-8.6,8.9-14.6c1.1-5.5,3.9-10.3,8.1-14.1c4.5-4.2,11.2-5.1,15.7-2.3
		c4.3,2.7,6,8.1,4.4,14.2c-2.1,8-7.9,12-15.5,13.9c-1.7,0.4-4.5-0.4-4.1,2.9c0.3,2.9,2.3,4.3,5.1,4.6c2.7,0.3,5-0.8,6.9-2.5
		c6.1-5.3,11.5-10.9,13.6-19.2c3.6-13.9,7.9-27.5,13-40.9c2.3-5.9,4.5-7.1,9.2-5.3c4.4,1.7,5.4,4,3.6,8.4
		c-2.8,6.8-5.6,13.5-8.4,20.3c-0.5,1.3-1.7,3-0.3,4.1c1.1,0.9,2.5-0.4,3.6-0.9c10.5-4.3,19.4-2,24.7,6.2c4.2,6.6,3.5,19.2-2.7,27.1
		c-6.2,8-16.1,12.1-24.5,9.8c-5.5-1.5-9.1-5.3-11.8-10.1c-2.9,3-0.4,10-7.3,9.1c-3.7-0.5-4.7-3.3-4.2-7.6c-2.7,2-4.7,3.6-6.8,4.9
		c-9.1,5.8-16.7,4.1-22.1-5.2c-1.5-2.6-2.5-2-4-0.3c-1.9,2.1-3.7,4.3-5.8,6.1c-4.7,3.9-9.4,2.9-12.3-2.5c-2.1-4-2.4-8.3-1.7-12.6
		c0.7-4.3,2.2-8.4,3.6-12.5c0.4-1.1,2.1-2.7-0.2-3.7c-1.9-0.7-3.1,0.1-3.9,2c-2.1,4.7-4.1,9.4-6.4,14.1c-2,4-4.2,8-7,11.5
		c-6.3,7.8-9.4,7.9-16.2,0.8c-8.6,8.7-14.4,5.9-19.2-2.1c-2.1,1.9-4,4-6.3,5.5c-6.4,4.4-12.9,1.2-13.6-6.5
		c-0.5-5.6,1.5-10.8,2.7-16.2c0.3-1.5,1.4-3.7-0.5-4.5c-1.8-0.7-2.9,1.4-3.5,2.9c-2.8,6.6-5.6,13.2-8.1,19.9
		c-1.1,2.9-1.6,6.3-5.5,5.8c-3.6-0.4-3.8-3.6-4.1-6.5c-0.1-1.2,0.8-3-0.7-3.6c-1.7-0.7-2.5,1.2-3.6,2.1c-5.9,4.7-12.2,8.2-20,7.8
		c-4.5-0.2-7.2-2.8-6.9-6.2c0.2-1.8,1.1-3.1,2.7-3.8c1.6-0.7,3-0.2,4,1.2c0.8,1.1,1.4,2.5,3.1,1.6c1.4-0.7,1.4-2.2,1.5-3.5
		c0.2-4.4-1.6-8.5-2.7-12.6c-1.1-3.8-2.6-7.6-2.6-11.6c0.2-7.7,6-13.5,13.6-13.8c8.5-0.2,13.6,6.7,11.1,14.8
		c-1.1,3.3-3.3,4.4-6.5,3.9c-2.9-0.4-4.7-1.5-4-4.9c0.2-1,0-2.1-1.3-2.2c-1.5-0.2-2,0.9-2.2,2.2c-0.2,2.2,0.1,4.3,1,6.4
		c1.9,4.8,3.9,9.5,5.6,14.4c1,2.8,2,1.9,3.4,0.5c4.5-4.5,8.1-9.3,9.6-15.9c3.2-13.6,7.4-27,13-39.8c2.1-4.9,5.5-5.8,10-3.3
		c3.1,1.7,4.3,3.9,2.4,7.4C-21.7,223.7-24.8,233.7-29.5,243.8z M107.1,262.6c0,4.9,3,8.3,7.8,8.4c5.9,0.2,11.3-5.2,11.5-11.4
		c0.2-5.2-3-8.8-7.9-9C112.4,250.5,107.2,256,107.1,262.6z M112,247.9c5.5-0.3,9.9-2.3,14.2-0.2c4.5,2.2,6.5,6.2,6.1,11
		c-0.4,5.6-3.1,10.1-7.9,13.2c-4.8,3.2-9.8,2.4-15.5,0c5.3,6.4,13.6,6.6,20.5,1.3c8.1-6.3,10.6-18.2,5.2-25.3
		C129.8,241.4,121.7,241,112,247.9z M13.5,250.5c-0.2-1.9,0.5-4-1.5-4.6c-2-0.6-2.9,1.3-3.7,2.7c-3.8,6.7-6.4,13.9-7.2,21.6
		c-0.2,1.5-0.4,3.4,1.2,4c1.9,0.8,3-1,3.9-2.4C10.5,265.3,12.3,257.9,13.5,250.5z M84.2,249.3c0-0.9,0.2-2.2-1.1-2.8
		c-1.3-0.6-2.1,0.5-2.8,1.4c-2.8,3.3-4.6,7.2-5.9,11.3c-0.2,0.7-0.8,1.6,0,2.3c1,0.7,1.9,0,2.7-0.5
		C81.3,258.4,83.3,254.4,84.2,249.3z M115.3,240.3c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.1,0.1,0.2,0.2,0.3
		C115.1,240.5,115.2,240.4,115.3,240.3z M-47,268.7c0.1-0.1,0.3-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.3,0.2-0.3,0.3
		C-47.2,268.5-47.1,268.6-47,268.7z M107.8,270.4l0.3,0.2l0-0.4L107.8,270.4z"/>
	<path class="st0" d="M185.3,275c-7.3,7.1-9.9,7.4-18.5,1.4c-2-1.4-2.7-0.1-3.5,1c-2.6,3.4-6,4.3-9.7,2.6c-3.6-1.7-5.9-4.6-6-8.9
		c-0.4-14.2,5-27.2,8.6-40.5c1.9-6.9,4.2-13.7,7.3-20.2c2.2-4.6,4.8-5.6,9.1-3.6c4.3,2,4.6,3.8,2.4,8.8c-6.7,15.9-13.4,31.9-16.9,49
		c-0.5,2.5-2.1,6.5,0.6,7c2.9,0.6,5.4-3.2,5.9-6.3c0.9-5.3,2.2-10.4,4.2-15.3c1.3-3.1,2.9-6,5-8.6c1.3-1.6,2.8-3,4.8-3.5
		c7.5-1.8,14.8,1,22.2,1.5c3.2,0.2,3.7,3.2,2.1,6.5c-3.2,6.9-6.5,13.9-8.1,21.4c-0.3,1.4-1.2,3.3,0.5,4c1.7,0.7,2.6-1.2,3.5-2.2
		c5.3-6.1,9-13.2,13-20.1c6.3-10.8,14.6-14.5,25.3-11.7c4.6,1.2,7.5,4.8,10.6,7.9c3.1,3.1,3.8,2,4.6-1.2c2.9-11.1,6.5-22,11-32.6
		c2.3-5.5,4.8-6.3,10.1-3.7c3.5,1.7,4.4,3.8,2.5,7.6c-7.9,16-14.2,32.6-19,49.8c-0.6,2.1-2.5,5.2-0.1,6.3c2.1,1,3.7-2.3,5-4
		c4.8-6.4,8.8-13.3,12.7-20.3c0.9-1.7,1.1-5.2,4.1-3.6c3,1.6-0.1,3.8-0.8,5.4c-4.1,9.1-8.3,18.2-14.6,26c-6.6,8.2-10.6,8-16.5-0.7
		c-4.6,4.9-10,8.4-17,8.1c-9.3-0.3-15.5-5.2-19-13.8c-0.4-0.9-0.8-2.2-1.5-2.4c-1.4-0.5-1.8,1.1-2.4,2c-1.9,2.5-3.5,5.3-5.5,7.7
		C195.5,282.5,191,282.3,185.3,275z M246.5,269.8c1.7-3.9,2.7-7.8,2.1-12.1c-0.3-2.5-0.7-4.6-3.2-5.8c4.1-1.4,1.7-3.4,0.2-4.8
		c-7.6-7.3-19.4-4.8-24.6,4.9c-3.5,6.5-2.7,14.6,2,20c4.4,5.1,10.7,6.4,18,3.5c1.5-0.6,3.7-1.1,3.4-3.6
		C246.1,272.1,246.6,271.3,246.5,269.8z M187.7,250.6c-0.5-1.6,0.7-4-1.6-4.7c-2.2-0.6-3.1,1.6-4,3.1c-4,6.4-6.6,13.3-7.5,20.8
		c-0.2,1.5-0.6,3.6,1.3,4.4c1.9,0.7,3.1-1,4-2.3C184.3,265.5,186.8,258.4,187.7,250.6z"/>
	<path class="st0" d="M115.3,240.3c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.3
		C115.2,240.1,115.3,240.2,115.3,240.3z"/>
	<path class="st0" d="M-47,268.7c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.2,0.3
		C-46.8,268.5-46.9,268.6-47,268.7z"/>
	<polygon class="st0" points="107.8,270.4 108.1,270.2 108.1,270.6 	"/>
	<path class="st0" d="M246.5,269.8c0.2,1.5-0.4,2.3-2,2c0,0,0.1,0.1,0.1,0.1c0.3-0.7,0.7-1.4,1-2.1c0,0-0.1,0.1-0.1,0.1
		C245.8,269.9,246.2,269.9,246.5,269.8L246.5,269.8z"/>
	<path class="st0" d="M245.5,269.8c-0.3,0.7-0.7,1.4-1,2.1c-9.7,4-15.9,2.4-19.2-5.2c-4.3-9.7,3.2-20.9,13.4-19.9
		c3.2,0.3,5.6,2,6.9,5.1c-4.6-0.3-9.4-2.3-13.2,2.4c-3,3.7-3.9,8.5-1.5,12.4c2.8,4.4,6.1,5.5,11.4,3.8
		C243.2,270.1,244.4,270,245.5,269.8z"/>
	<path class="st0" d="M245.6,255.8c-1.2,0-2.3-0.2-3.5-0.1c-3.1,0.3-4.8,2.1-4.9,5.2c-0.1,4.1,2.8,5.1,6.9,5.3
		c-4.2,1.8-7.4,2.3-9.5-1.8c-1.8-3.6-1.4-7.2,1.7-9.7C239.5,252.3,242.9,252.4,245.6,255.8L245.6,255.8z"/>
	<path class="st0" d="M245.5,255.8c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0.1-0.3,0.1-0.5,0.2C245.6,256.4,245.6,256.1,245.5,255.8
		C245.6,255.8,245.5,255.8,245.5,255.8z"/>
	<path class="st0" d="M246.5,269.9c-0.4,0-0.7,0-1.1,0c0.1-0.2,0.2-0.5,0.3-0.7C246,269.4,246.3,269.7,246.5,269.9z"/>
</g>
<g>
	<path class="st0" d="M97.6,0c54.2,0,98.2,43.6,98.3,97.5c0.1,53.6-44.2,97.7-98.2,97.9C44,195.5,0,151.4,0,97.4
		C0,43.5,43.5,0,97.6,0z M97.6,185.1c48.3,0,87.6-38.6,87.8-86.3C185.5,50,145.7,10,97.3,10.2c-47.7,0.2-87.5,39.6-87.7,87
		C9.5,145.7,48.8,185,97.6,185.1z"/>
	<path class="st0" d="M89.5,103.7C79.2,95.3,69.3,86.9,60,77.8C53.1,71.1,46.6,64,41,56c-2.1-3-3.9-6.2-4.5-9.9
		c-0.4-2.3,0.2-4.4,2.2-5.7c2.1-1.3,4-0.2,5.4,1.2c18.5,17.2,38.4,32.9,53.3,53.7c3,4.2,7.5,6.6,11.1,10
		c14.8,14,29.1,28.3,46.2,39.6c3.5,2.3,3.1,5.7,2.3,9c-1,3.8-3.7,5.7-7.6,6.2c-3.3,0.4-5.8,0.2-7.7-3.5
		c-6.9-12.6-17.7-21.9-28.1-31.5c-4.3-3.9-8.8-7.6-13.2-11.5c-0.8-0.7-1.7-1.2-2.2-2c-3.4-5.5-3.4-5.6-7.7-1.1
		C79.2,122.2,68,134,56.8,145.9c-3.2,3.4-6.2,7-8.5,11.1c-1.7,3-4.6,3.1-7.4,2.7c-2.9-0.3-4.9-2-5.5-5c-0.6-2.9-0.6-5.9,2.2-7.6
		c17.3-10.9,31.4-25.6,46.6-39C85.7,106.7,87.4,105.4,89.5,103.7z"/>
	<path class="st0" d="M120.3,78.1c10.4-10,20.8-20,31.2-30c0.3,0.3,0.7,0.7,1,1c-8.3,11.6-21.2,19-28.6,31.5
		c10.7-8.4,19.6-18.8,30.4-27.6c0.3,3.5-1.9,5.3-3.5,7.1c-7.9,9.2-16.2,18.1-24.8,26.7c-2.9,2.9-5.9,4.6-10.2,3.3
		c-4.5-1.3-7,2-9.8,4.6c-1.1,1.1-2.2,3.5-4.4,1.1c-1.9-2.1-0.8-3.5,1-4.8c4.3-2.9,6.3-6.5,4.1-11.9c-1.1-2.7,0.6-5.2,2.6-7.2
		c10.7-10.8,21.8-21,33.6-30.6c1.2-1,2.4-2,4.5-0.8c-11,10.7-22,21.2-32.9,31.8c0.4,0.4,0.7,0.8,1.1,1.2
		c12.2-9.8,22.7-21.5,34.7-31.6c0.4,0.4,0.8,0.9,1.2,1.3c-10.9,11.1-21.8,22.3-32.6,33.4C119.3,77.1,119.8,77.6,120.3,78.1z"/>
	<path class="st0" d="M90.8,72c-1.9,0.1-4.1-0.2-5.9-1.5c-2.6-1.8-3.3-4.4-1.3-6.9c1.8-2.3,4.2-2.8,6.1,0.2c0.6,0.9,1.4,1.5,2.5,1.2
		c1.3-0.4,1.6-1.6,1.7-2.8c0.2-4.1-0.8-7.9-2-11.7c-1.2-3.6-2.4-7.3-2.9-11.1c-0.7-5.1,1.2-9.6,5.6-12.5c4.3-2.9,8.9-3.7,13.7-1.5
		c4.9,2.2,7.2,8,5.5,13c-1.1,3.1-3.1,4.6-6.4,4.2c-3-0.4-5.2-1.5-4.5-5.1c0.2-1-0.1-2.1-1.4-2.2c-1.5-0.1-2,1-2.2,2.2
		c-0.3,2,0.1,4,0.8,5.9c1.2,3.3,2.5,6.5,3.9,9.7C108.8,64,103.4,72,90.8,72z"/>
	<path class="st0" d="M89.5,160.9c2.4-0.2,5,0.3,7.1-1.6c1.9-1.8,3.3-3.9,2.7-6.6c-0.4-2.1-1.6-3.6-3.9-4c-2.9-0.5-5.2,0.3-7,2.7
		c-1.9,2.5-1.3,5.9-2.7,8.4c-1.3,2.5-0.6,7.8-5.1,6.5c-4.3-1.2-1.7-5.4-0.9-8.1c3.1-10.5,5.6-21.2,9.7-31.4c0.7-1.7,1.3-3.4,2.2-5
		c1.4-2.4,3.5-2.2,5.6-1.2c2.1,1,2.5,2.6,1.5,4.9c-2,5-5.3,9.6-5.5,15.3c-0.2,0.2-0.3,0.3-0.5,0.5c0.2-0.1,0.4-0.2,0.6-0.4
		c0.2,0,0.3,0,0.5,0c9.5-3.1,15.4-1.2,17.8,5.8c2.6,7.7-2.4,17.3-10.7,19.9c-3.8,1.2-7.6,1.8-11.4-1.1c1.8-1.2,3.5-0.6,5.1-0.9
		c6-0.9,10.6-5.2,11.2-11.1c0.5-4.3-0.9-8.2-5.4-9.6c-3.8-1.2-7.8-1.5-10.2,2.9c-0.2,0.1-0.3,0.2-0.5,0.4c0.1,0,0.2,0,0.3,0
		c0.1-0.1,0.1-0.3,0.2-0.4c6.9-1.9,10.6-0.7,11.9,3.9c1.3,4.6-1.9,10.3-6.8,11.7C93.4,163,90.9,163.6,89.5,160.9L89.5,160.9z"/>
	<path class="st0" d="M88.5,159.9c-0.2-0.1-0.4-0.2-0.5-0.4c0.1,0,0.3,0,0.3,0C88.4,159.6,88.4,159.8,88.5,159.9
		C88.4,159.9,88.5,159.9,88.5,159.9z"/>
	<path class="st0" d="M89.5,160.9c-0.7,0-1-0.3-1-1c0,0-0.1,0.1-0.1,0.1C89.1,160,89.5,160.2,89.5,160.9
		C89.5,160.9,89.5,160.9,89.5,160.9z"/>
	<path class="st0" d="M93.5,140.9c-0.2,0.1-0.4,0.2-0.6,0.4C93.1,141.2,93.2,141,93.5,140.9C93.4,140.8,93.5,140.9,93.5,140.9z"/>
	<path class="st0" d="M90.4,146.9c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.2,0-0.3,0C90.2,147.2,90.3,147.1,90.4,146.9
		C90.5,147,90.4,146.9,90.4,146.9z"/>
</g>
`]
