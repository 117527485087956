import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from
} from "@apollo/client";
// import { RestLink } from "apollo-link-rest";
import { onError } from "@apollo/client/link/error";


// const restLink = new RestLink({
// endpoints: {
//   openExchangeRate: "https://open.exchangerate-api.com/v6/",
// },
// });

const errorLink = onError(({ graphqlErrors, networkError }) => {
  if (graphqlErrors) {
    graphqlErrors.map(({ message, location, path }) => {
      alert(`Graphql error ${message}`)
      return {
        message: message,
        location: location,
        path: path
      }
    });
  }
});

const link = from([
  errorLink,
  new HttpLink({
    // uri: "https://48p1r2roz4.sse.codesandbox.io",
    uri: process.env.REACT_APP_API_URL,
  })
]);
// const httpLink = new HttpLink({
// uri: "https://48p1r2roz4.sse.codesandbox.io",
// });

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([link]),
});
