import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import { client } from "./ApolloClient/client";
import { ApolloProvider } from '@apollo/client';


const loading = (
  <div style={{ textAlign: "center", marginTop: "15%" }}>
    <div>
      <div className="spinner-border text-info" style={{ width: "2.7rem", height: "2.7rem" }} role="status">
        <span className="visually" style={{ fontSize: "large" }}>Loading...</span>
      </div>
    </div>
  </div>
)



// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  render() {
    return (
      <ApolloProvider client={client}>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/admin/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              <Route path="/" name="Home" render={props => <TheLayout {...props} />} />
              {/* <Route render={props => <Page404 {...props} />} /> */}

            </Switch>
          </React.Suspense>
        </HashRouter>
      </ApolloProvider>
    );
  }
}

export default App;
