export const logo = ['608 134', `
<title>Shareblad logo</title>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 viewBox="0 0 358.3 76.9" style="enable-background:new 0 0 358.3 76.9;" xml:space="preserve">
<style type="text/css">
.st0{fill:#FE7D52;}
</style>
<g>
<path class="st0" d="M48.9,38.4c3.5-0.7,4.6-3.3,6.7-4.4c2.9-1.6,5.8-3,8.9-0.9c3.2,2.1,3.7,5.3,2.6,8.8c-2,6.7-4.1,13.4-6,20.1
  c-0.4,1.4-1.1,3.3,0.3,4.2c1.6,1,2.6-0.9,3.6-1.9c3-2.8,5.1-6,5.9-10.2c1.1-5.6,3-10.9,6-15.8c4.2-6.8,9.3-8.3,16.4-4.6
  c1.3,0.6,1.9,0.7,3-0.1c3.4-2.4,6.4-0.5,9.1,1.5c2.9,2.2,0.4,4.5-0.4,6.7c-2.5,6.7-5.5,13.2-6.9,20.3c-0.3,1.3-1,3.2,0.8,3.9
  c1.4,0.6,2.3-1.1,3.1-2.1c5.4-7,9.5-14.9,13.4-22.8c1-2.1-0.1-3.4-1-5c-2.1-3.8-1.8-7.4,1.1-10.6c1.8-2.1,4.3-2.8,6.8-1.7
  c2.6,1.1,3.5,3.4,3.2,6.2c-0.5,5.6-0.5,5.7,5.2,4.3c3-0.7,5.3,0.4,7.2,2.4c2,2.2,1.1,4.6-0.3,6.9c-3.5,5.7-6.9,11.4-8,18.2
  c-0.2,1.4-0.8,3.4,0.5,4.2c1.8,1.1,3-0.9,4-2c4.1-4.2,7.7-8.6,8.9-14.6c1.1-5.5,3.9-10.3,8.1-14.1c4.5-4.2,11.2-5.1,15.7-2.3
  c4.3,2.7,6,8.1,4.4,14.2c-2.1,8-7.9,12-15.5,13.9c-1.7,0.4-4.5-0.4-4.1,2.9c0.3,2.9,2.3,4.3,5.1,4.6c2.7,0.3,5-0.8,6.9-2.5
  c6.1-5.3,11.5-10.9,13.6-19.2c3.6-13.9,7.9-27.5,13-40.9c2.3-5.9,4.5-7.1,9.2-5.3c4.4,1.7,5.4,4,3.6,8.4
  c-2.8,6.8-5.6,13.5-8.4,20.3c-0.5,1.3-1.7,3-0.3,4.1c1.1,0.9,2.5-0.4,3.6-0.9c10.5-4.3,19.4-2,24.7,6.2c4.2,6.6,3.5,19.2-2.7,27.1
  c-6.2,8-16.1,12.1-24.5,9.8c-5.5-1.5-9.1-5.3-11.8-10.1c-2.9,3-0.4,10-7.3,9.1c-3.7-0.5-4.7-3.3-4.2-7.6c-2.7,2-4.7,3.6-6.8,4.9
  c-9.1,5.8-16.7,4.1-22.1-5.2c-1.5-2.6-2.5-2-4-0.3c-1.9,2.1-3.7,4.3-5.8,6.1c-4.7,3.9-9.4,2.9-12.3-2.5c-2.1-4-2.4-8.3-1.7-12.6
  c0.7-4.3,2.2-8.4,3.6-12.5c0.4-1.1,2.1-2.7-0.2-3.7c-1.9-0.7-3.1,0.1-3.9,2c-2.1,4.7-4.1,9.4-6.4,14.1c-2,4-4.2,8-7,11.5
  c-6.3,7.8-9.4,7.9-16.2,0.8c-8.6,8.7-14.4,5.9-19.2-2.1c-2.1,1.9-4,4-6.3,5.5c-6.4,4.4-12.9,1.2-13.6-6.5
  c-0.5-5.6,1.5-10.8,2.7-16.2c0.3-1.5,1.4-3.7-0.5-4.5c-1.8-0.7-2.9,1.4-3.5,2.9c-2.8,6.6-5.6,13.2-8.1,19.9
  c-1.1,2.9-1.6,6.3-5.5,5.8c-3.6-0.4-3.8-3.6-4.1-6.5c-0.1-1.2,0.8-3-0.7-3.6c-1.7-0.7-2.5,1.2-3.6,2.1c-5.9,4.7-12.2,8.2-20,7.8
  c-4.5-0.2-7.2-2.8-6.9-6.2c0.2-1.8,1.1-3.1,2.7-3.8c1.6-0.7,3-0.2,4,1.2c0.8,1.1,1.4,2.5,3.1,1.6c1.4-0.7,1.4-2.2,1.5-3.5
  c0.2-4.4-1.6-8.5-2.7-12.6C7.5,47.2,5.9,43.5,6,39.4c0.2-7.7,6-13.5,13.6-13.8c8.5-0.2,13.6,6.7,11.1,14.8
  c-1.1,3.3-3.3,4.4-6.5,3.9c-2.9-0.4-4.7-1.5-4-4.9c0.2-1,0-2.1-1.3-2.2c-1.5-0.2-2,0.9-2.2,2.2c-0.2,2.2,0.1,4.3,1,6.4
  c1.9,4.8,3.9,9.5,5.6,14.4c1,2.8,2,1.9,3.4,0.5c4.5-4.5,8.1-9.3,9.6-15.9c3.2-13.6,7.4-27,13-39.8c2.1-4.9,5.5-5.8,10-3.3
  c3.1,1.7,4.3,3.9,2.4,7.4C56.8,18.2,53.6,28.2,48.9,38.4z M185.6,57.2c0,4.9,3,8.3,7.8,8.4c5.9,0.2,11.3-5.2,11.5-11.4
  c0.2-5.2-3-8.8-7.9-9C190.9,45.1,185.6,50.6,185.6,57.2z M190.4,42.4c5.5-0.3,9.9-2.3,14.2-0.2c4.5,2.2,6.5,6.2,6.1,11
  c-0.4,5.6-3.1,10.1-7.9,13.2c-4.8,3.2-9.8,2.4-15.5,0c5.3,6.4,13.6,6.6,20.5,1.3c8.1-6.3,10.6-18.2,5.2-25.3
  C208.2,35.9,200.1,35.6,190.4,42.4z M92,45.1c-0.2-1.9,0.5-4-1.5-4.6c-2-0.6-2.9,1.3-3.7,2.7c-3.8,6.7-6.4,13.9-7.2,21.6
  c-0.2,1.5-0.4,3.4,1.2,4c1.9,0.8,3-1,3.9-2.4C89,59.8,90.7,52.4,92,45.1z M162.6,43.9c0-0.9,0.2-2.2-1.1-2.8
  c-1.3-0.6-2.1,0.5-2.8,1.4c-2.8,3.3-4.6,7.2-5.9,11.3c-0.2,0.7-0.8,1.6,0,2.3c1,0.7,1.9,0,2.7-0.5C159.7,52.9,161.7,49,162.6,43.9z
   M193.8,34.9c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1,0.1-0.3,0.2-0.3,0.3c0,0.1,0.1,0.2,0.2,0.3C193.5,35,193.7,34.9,193.8,34.9z
   M31.4,63.3c0.1-0.1,0.3-0.2,0.3-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1,0.1-0.3,0.2-0.3,0.3C31.2,63,31.3,63.2,31.4,63.3z M186.2,64.9
  l0.3,0.2l0-0.4L186.2,64.9z"/>
<path class="st0" d="M263.7,69.6c-7.3,7.1-9.9,7.4-18.5,1.4c-2-1.4-2.7-0.1-3.5,1c-2.6,3.4-6,4.3-9.7,2.6c-3.6-1.7-5.9-4.6-6-8.9
  c-0.4-14.2,5-27.2,8.6-40.5c1.9-6.9,4.2-13.7,7.3-20.2c2.2-4.6,4.8-5.6,9.1-3.6c4.3,2,4.6,3.8,2.4,8.8c-6.7,15.9-13.4,31.9-16.9,49
  c-0.5,2.5-2.1,6.5,0.6,7c2.9,0.6,5.4-3.2,5.9-6.3c0.9-5.3,2.2-10.4,4.2-15.3c1.3-3.1,2.9-6,5-8.6c1.3-1.6,2.8-3,4.8-3.5
  c7.5-1.8,14.8,1,22.2,1.5c3.2,0.2,3.7,3.2,2.1,6.5c-3.2,6.9-6.5,13.9-8.1,21.4c-0.3,1.4-1.2,3.3,0.5,4c1.7,0.7,2.6-1.2,3.5-2.2
  c5.3-6.1,9-13.2,13-20.1c6.3-10.8,14.6-14.5,25.3-11.7c4.6,1.2,7.5,4.8,10.6,7.9c3.1,3.1,3.8,2,4.6-1.2c2.9-11.1,6.5-22,11-32.6
  c2.3-5.5,4.8-6.3,10.1-3.7c3.5,1.7,4.4,3.8,2.5,7.6c-7.9,16-14.2,32.6-19,49.8c-0.6,2.1-2.5,5.2-0.1,6.3c2.1,1,3.7-2.3,5-4
  c4.8-6.4,8.8-13.3,12.7-20.3c0.9-1.7,1.1-5.2,4.1-3.6c3,1.6-0.1,3.8-0.8,5.4c-4.1,9.1-8.3,18.2-14.6,26c-6.6,8.2-10.6,8-16.5-0.7
  c-4.6,4.9-10,8.4-17,8.1c-9.3-0.3-15.5-5.2-19-13.8c-0.4-0.9-0.8-2.2-1.5-2.4c-1.4-0.5-1.8,1.1-2.4,2c-1.9,2.5-3.5,5.3-5.5,7.7
  C273.9,77,269.4,76.8,263.7,69.6z M324.9,64.4c1.7-3.9,2.7-7.8,2.1-12.1c-0.3-2.5-0.7-4.6-3.2-5.8c4.1-1.4,1.7-3.4,0.2-4.8
  c-7.6-7.3-19.4-4.8-24.6,4.9c-3.5,6.5-2.7,14.6,2,20c4.4,5.1,10.7,6.4,18,3.5c1.5-0.6,3.7-1.1,3.4-3.6
  C324.5,66.6,325.1,65.9,324.9,64.4z M266.2,45.2c-0.5-1.6,0.7-4-1.6-4.7c-2.2-0.6-3.1,1.6-4,3.1c-4,6.4-6.6,13.3-7.5,20.8
  c-0.2,1.5-0.6,3.6,1.3,4.4c1.9,0.7,3.1-1,4-2.3C262.7,60,265.2,52.9,266.2,45.2z"/>
<path class="st0" d="M193.8,34.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.3
  C193.6,34.6,193.7,34.7,193.8,34.9z"/>
<path class="st0" d="M31.4,63.3c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1,0.2-0.2,0.3-0.3c0.1,0.1,0.2,0.2,0.2,0.3
  C31.7,63.1,31.5,63.2,31.4,63.3z"/>
<polygon class="st0" points="186.2,64.9 186.6,64.8 186.5,65.2 	"/>
<path class="st0" d="M324.9,64.4c0.2,1.5-0.4,2.3-2,2c0,0,0.1,0.1,0.1,0.1c0.3-0.7,0.7-1.4,1-2.1c0,0-0.1,0.1-0.1,0.1
  C324.2,64.5,324.6,64.5,324.9,64.4L324.9,64.4z"/>
<path class="st0" d="M324,64.4c-0.3,0.7-0.7,1.4-1,2.1c-9.7,4-15.9,2.4-19.2-5.2c-4.3-9.7,3.2-20.9,13.4-19.9
  c3.2,0.3,5.6,2,6.9,5.1c-4.6-0.3-9.4-2.3-13.2,2.4c-3,3.7-3.9,8.5-1.5,12.4c2.8,4.4,6.1,5.5,11.4,3.8
  C321.7,64.7,322.8,64.6,324,64.4z"/>
<path class="st0" d="M324,50.4c-1.2,0-2.3-0.2-3.5-0.1c-3.1,0.3-4.8,2.1-4.9,5.2c-0.1,4.1,2.8,5.1,6.9,5.3
  c-4.2,1.8-7.4,2.3-9.5-1.8c-1.8-3.6-1.4-7.2,1.7-9.7C317.9,46.9,321.3,46.9,324,50.4L324,50.4z"/>
<path class="st0" d="M323.9,50.4c0.2,0.2,0.4,0.5,0.6,0.7c-0.2,0.1-0.3,0.1-0.5,0.2C324,51,324,50.7,323.9,50.4
  C324,50.4,323.9,50.4,323.9,50.4z"/>
<path class="st0" d="M325,64.5c-0.4,0-0.7,0-1.1,0c0.1-0.2,0.2-0.5,0.3-0.7C324.4,64,324.7,64.2,325,64.5z"/>
</g>
<g>
<path class="st0" d="M176-347.9c54.2,0,98.2,43.6,98.3,97.5c0.1,53.6-44.2,97.7-98.2,97.9c-53.8,0.1-97.8-43.9-97.8-98
  C78.4-304.4,122-347.8,176-347.9z M176.1-162.8c48.3,0,87.6-38.6,87.8-86.3c0.1-48.7-39.7-88.7-88.1-88.5
  c-47.7,0.2-87.5,39.6-87.7,87C87.9-202.2,127.3-162.8,176.1-162.8z"/>
<path class="st0" d="M167.9-244.1c-10.3-8.5-20.2-16.9-29.5-25.9c-7-6.7-13.4-13.9-19-21.8c-2.1-3-3.9-6.2-4.5-9.9
  c-0.4-2.3,0.2-4.4,2.2-5.7c2.1-1.3,4-0.2,5.4,1.2c18.5,17.2,38.4,32.9,53.3,53.7c3,4.2,7.5,6.6,11.1,10
  c14.8,14,29.1,28.3,46.2,39.6c3.5,2.3,3.1,5.7,2.3,9c-1,3.8-3.7,5.7-7.6,6.2c-3.3,0.4-5.8,0.2-7.7-3.5
  c-6.9-12.6-17.7-21.9-28.1-31.5c-4.3-3.9-8.8-7.6-13.2-11.5c-0.8-0.7-1.7-1.2-2.2-2c-3.4-5.5-3.4-5.6-7.7-1.1
  c-11.3,11.8-22.5,23.6-33.7,35.5c-3.2,3.4-6.2,7-8.5,11.1c-1.7,3-4.6,3.1-7.4,2.7c-2.9-0.3-4.9-2-5.5-5c-0.6-2.9-0.6-5.9,2.2-7.6
  c17.3-10.9,31.4-25.6,46.6-39C164.1-241.2,165.9-242.5,167.9-244.1z"/>
<path class="st0" d="M198.7-269.7c10.4-10,20.8-20,31.2-30c0.3,0.3,0.7,0.7,1,1c-8.3,11.6-21.2,19-28.6,31.5
  c10.7-8.4,19.6-18.8,30.4-27.6c0.3,3.5-1.9,5.3-3.5,7.1c-7.9,9.2-16.2,18.1-24.8,26.7c-2.9,2.9-5.9,4.6-10.2,3.3
  c-4.5-1.3-7,2-9.8,4.6c-1.1,1.1-2.2,3.5-4.4,1.1c-1.9-2.1-0.8-3.5,1-4.8c4.3-2.9,6.3-6.5,4.1-11.9c-1.1-2.7,0.6-5.2,2.6-7.2
  c10.7-10.8,21.8-21,33.6-30.6c1.2-1,2.4-2,4.5-0.8c-11,10.7-22,21.2-32.9,31.8c0.4,0.4,0.7,0.8,1.1,1.2
  c12.2-9.8,22.7-21.5,34.7-31.6c0.4,0.4,0.8,0.9,1.2,1.3c-10.9,11.1-21.8,22.3-32.6,33.4C197.8-270.7,198.2-270.2,198.7-269.7z"/>
<path class="st0" d="M169.2-275.9c-1.9,0.1-4.1-0.2-5.9-1.5c-2.6-1.8-3.3-4.4-1.3-6.9c1.8-2.3,4.2-2.8,6.1,0.2
  c0.6,0.9,1.4,1.5,2.5,1.2c1.3-0.4,1.6-1.6,1.7-2.8c0.2-4.1-0.8-7.9-2-11.7c-1.2-3.6-2.4-7.3-2.9-11.1c-0.7-5.1,1.2-9.6,5.6-12.5
  c4.3-2.9,8.9-3.7,13.7-1.5c4.9,2.2,7.2,8,5.5,13c-1.1,3.1-3.1,4.6-6.4,4.2c-3-0.4-5.2-1.5-4.5-5.1c0.2-1-0.1-2.1-1.4-2.2
  c-1.5-0.1-2,1-2.2,2.2c-0.3,2,0.1,4,0.8,5.9c1.2,3.3,2.5,6.5,3.9,9.7C187.3-283.9,181.8-275.8,169.2-275.9z"/>
<path class="st0" d="M167.9-186.9c2.4-0.2,5,0.3,7.1-1.6c1.9-1.8,3.3-3.9,2.7-6.6c-0.4-2.1-1.6-3.6-3.9-4c-2.9-0.5-5.2,0.3-7,2.7
  c-1.9,2.5-1.3,5.9-2.7,8.4c-1.3,2.5-0.6,7.8-5.1,6.5c-4.3-1.2-1.7-5.4-0.9-8.1c3.1-10.5,5.6-21.2,9.7-31.4c0.7-1.7,1.3-3.4,2.2-5
  c1.4-2.4,3.5-2.2,5.6-1.2c2.1,1,2.5,2.6,1.5,4.9c-2,5-5.3,9.6-5.5,15.3c-0.2,0.2-0.3,0.3-0.5,0.5c0.2-0.1,0.4-0.2,0.6-0.4
  c0.2,0,0.3,0,0.5,0c9.5-3.1,15.4-1.2,17.8,5.8c2.6,7.7-2.4,17.3-10.7,19.9c-3.8,1.2-7.6,1.8-11.4-1.1c1.8-1.2,3.5-0.6,5.1-0.9
  c6-0.9,10.6-5.2,11.2-11.1c0.5-4.3-0.9-8.2-5.4-9.6c-3.8-1.2-7.8-1.5-10.2,2.9c-0.2,0.1-0.3,0.2-0.5,0.4c0.1,0,0.2,0,0.3,0
  c0.1-0.1,0.1-0.3,0.2-0.4c6.9-1.9,10.6-0.7,11.9,3.9c1.3,4.6-1.9,10.3-6.8,11.7C171.8-184.8,169.4-184.2,167.9-186.9L167.9-186.9z"
  />
<path class="st0" d="M166.9-188c-0.2-0.1-0.4-0.2-0.5-0.4c0.1,0,0.3,0,0.3,0C166.8-188.2,166.8-188.1,166.9-188
  C166.9-187.9,166.9-188,166.9-188z"/>
<path class="st0" d="M167.9-186.9c-0.7,0-1-0.3-1-1c0,0-0.1,0.1-0.1,0.1C167.5-187.9,167.9-187.6,167.9-186.9
  C167.9-186.9,167.9-186.9,167.9-186.9z"/>
<path class="st0" d="M171.9-206.9c-0.2,0.1-0.4,0.2-0.6,0.4C171.5-206.7,171.7-206.9,171.9-206.9
  C171.8-207,171.9-206.9,171.9-206.9z"/>
<path class="st0" d="M168.9-201c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.1-0.2,0-0.3,0C168.6-200.7,168.8-200.8,168.9-201
  C168.9-200.9,168.9-201,168.9-201z"/>
</g>
  
`]
